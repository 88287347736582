import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou2 } from "./features";

// hero image
import EntryIllustration from '../../../../../assets/img/openAPI-Img.svg'
import EntryBlurIllustration from '../../../../../assets/img/business/blur/business-api-hero-blur.svg'
import DashboardIcon from "../../../../../assets/img/light-dashboard.inline.svg"
import SendBlackIcon from "../../../../../assets/img/kuda-send-black.inline.svg"
import KudaPOSTerminalIcon from "../../../../../assets/img/Pos-terminal.inline.svg"

import CustomerBaseImage from "../../../../../assets/img/business/open-api/customer-base-illustration.svg"
import PaymentServicesImage from "../../../../../assets/img/business/open-api/payment-services-illustration.svg"
import settleAccountsImage from "../../../../../assets/img/business/open-api/settle-accounts-illustration.svg"
import revenueCommissionsImage from "../../../../../assets/img/business/open-api/revenue-commissions-illustration.svg"

import { Img } from "react-image";


const entryContent = {
  title: (<span>
    <span className="color-secondary">Power</span> your business to success with the Kuda Business API.
  </span>),
  subtitle: "Get sub accounts, full access to Kuda’s payment APIs, instant settlement for agents and more!",
  name: "Get API Product",
  url: "/en-ng/business/api-application-form/",
}

const businessTopFeatures = [
  {
    icon: <DashboardIcon />,
    text: "Create and manage sub accounts."
  },
  {
    icon: <SendBlackIcon />,
    text: "Explore, test and build with Kuda’s payment APIs."
  },
  {
    icon: <KudaPOSTerminalIcon />,
    text: "Settle agent accounts immediately after POS transactions."
  },
]

const CustomerBase = {
  title: "Grow your customer base with sub accounts.",
  subtitle: "Create fully functional accounts, make transfers and analyse transaction history on any account easily.",
  url: "/en-ng/business/api-application-form/",
  name: "Get API Product",
  illustration: (
    <Img src={CustomerBaseImage} className="kuda-business-image" />
  ),
}

const PaymentServices = {
  title: "Build faster with Kuda’s payment services.",
  subtitle: "Integrate bill payments, transfer services and savings, issue cards and set up webhooks for instant notifications quickly.",
  illustration: (
    <Img src={PaymentServicesImage} className="kuda-business-image" />
  ),
}

const settleAccounts = {
  title: "Settle accounts on your agent network instantly.",
  subtitle: "Our API makes instant settlement after any POS transaction possible for agency banking aggregators.",
  url: "/en-ng/business/api-application-form/",
  name: "Get API Product",
  illustration: (
    <Img src={settleAccountsImage} className="kuda-business-image" />
  ),
}

const revenueCommissions = {
  title: "Increase your revenue with commissions.",
  subtitle: "With our bill payment API, you’ll earn commission every time your customers buy airtime or pay a bill.",
  illustration: (
    <Img src={revenueCommissionsImage} className="kuda-business-image" />
  ),
}

const questions = [
  {
    heading: "What is the Kuda Business API?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16 mb-4">The Kuda Business API is a developer-friendly Open Banking API solution that helps businesses scale by giving them access to Kuda’s account opening, payment and instant settlement services.</span>

        <span className="faq-description f-16">Our end-to-end financial services API gives startups and corporates real-time data access, up-to-date documentation and the power to make secure transactions.</span>

      </span>),
    index: 1
  },
  {
    heading: "What services does the Kuda Business API offer?",
    list: (

      <span className="pt-3 career-message-margin-bottom ">
        <div className="flex flex-column  leading-md-6 f-md-16">
          <div className="mb-3">Sub accounts</div>

          <div className="mb-3">Account transactions</div>

          <div className="mb-3">Bill payments</div>

          <div className="mb-3">Gift cards</div>

          <div className="mb-3">Debit card issuance</div>

          <div className="mb-3">Savings</div>

          <div className="mb-3">Instant settlement</div>

          <div className="mb-3">Webhooks</div>

        </div>
      </span>

    ),
    index: 2
  },
  {
    heading: "How do I get access to the Kuda Business API?",
    list: (
      <div className="flex flex-column  mt-2">

        <span className="faq-description f-16">To get access to the Kuda Business API, follow the steps below:</span><br />

        <span className="pt-3 career-message-margin-bottom ">

          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Open a full Kuda Business account <a href="https://kudabusiness.onelink.me/epwz/jceed731/" target="_blank" rel="noopener noreferrer"  className="cursor-pointer link-decoration">here.</a> You’ll need your CAC registration documents.</div>

            <div className="mb-3">2. Click Business API on your Kuda Business dashboard.</div>

            <div className="mb-3">3. Click Activate Business API, then follow the prompts on your screen.</div>


          </div>
        </span>
      </div>),
    index: 3
  },


]

const BusinessAPI = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryBlurIllustration} className="hero-illustration" />}
        isExternal={true}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={CustomerBase.title}
        subtitle={CustomerBase.subtitle}
        illustration={CustomerBase.illustration}
        name={CustomerBase.name}
        url={CustomerBase.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={PaymentServices.title}
        subtitle={PaymentServices.subtitle}
        illustration={PaymentServices.illustration}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={settleAccounts.title}
        subtitle={settleAccounts.subtitle}
        illustration={settleAccounts.illustration}
        name={settleAccounts.name}
        url={settleAccounts.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={revenueCommissions.title}
        subtitle={revenueCommissions.subtitle}
        illustration={revenueCommissions.illustration}
        isExternal={true}
      />
      <FAQList title={"Business API FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou2} />
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessAPI

